<script lang="ts">
  import { discounts, periodOptions, type Currency, type Period, type Product } from "$lib/products";
  import { getFormattedDiscount, getFormattedPrice } from "$lib/utils";
  import Icon from "@brave/leo/src/components/icon/icon.svelte";
  import Label from "@brave/leo/src/components/label/label.svelte";
  import RadioButton from "@brave/leo/src/components/radioButton/radioButton.svelte";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import CurrencyDropdown from "./CurrencyDropdown.svelte";
  import Logo from "./ProductLogo.svelte";
  import UnsupportedSystem from "./UnsupportedSystem.svelte";

  export let product: Product;
  export let selectedCurrency: Currency = "USD";
  export let selectedPeriod: Period = "year";

  const { name, baseName, description, features, prices, hasFreeVersion, trialDays } = product;

  onMount(() => {
    document.body.classList.add("product-landing");

    return () => {
      document.body.classList.remove("product-landing");
    };
  });

  $: availableCurrencies = new Set<Currency>([...prices.values()].map(p => p.currency));
  $: priceOptions = [...prices.values()].filter((price) => price.currency === selectedCurrency).sort((a, b) => {
    if (a.period === "year" && b.period !== "year") {
      return -1;
    } else {
      return 0;
    }
  })
</script>

<main
  class="box relative overflow-hidden rounded-none p-0 before:absolute before:left-0 before:top-0 before:z-0 before:block before:h-[299px] before:w-full before:-translate-y-3/4 before:rounded-[720px] before:bg-primary-10 before:blur-[100px] md:max-w-[720px]"
  in:fade={{ duration: 600 }}
>
  <section
    class="box overflow-hidden rounded-none border-b border-divider-subtle shadow-none max-sm:p-xl max-sm:pt-2xl"
  >
    <header class="relative z-10 flex items-center justify-center gap-2xl pb-m">
      <Logo {name} />
      <div class="flex flex-col justify-center gap-s">
        <h1 class="text-h2">{baseName}</h1>
        {#if hasFreeVersion}
          <p class="text-large-regular">Premium</p>
        {/if}
      </div>
    </header>
    <div
      class="relative z-10 grid items-center gap-xl p-2xl text-text-secondary sm:grid-cols-2 sm:gap-3xl sm:pb-6xl sm:pt-2xl md:grid-cols-[312px_auto]"
    >
      <p>{description}</p>
      <ul class="flex flex-col gap-s" style="--leo-icon-color: var(--leo-color-primary-40)">
        {#each features as feature}
          <li class="flex gap-m"><Icon name="check-normal" /> {feature}</li>
        {/each}
        <li class="flex gap-m"><Icon name="check-normal" /> Cancel any time</li>
        {#if trialDays > 0}
          <li class="flex gap-m font-semibold text-primary-40">
            <Icon name="check-normal" />
            {trialDays}-day free trial
          </li>
        {/if}
      </ul>
    </div>
    <div class="rounded-m border border-divider-subtle/40">
      {#if availableCurrencies.size > 1}
        <div class="grid gap-y-m gap-x-xl px-xl py-l min-[748px]:grid-cols-[1fr_auto] max-[747px]:[&_.label-row]:!hidden">
          <CurrencyDropdown {availableCurrencies} bind:value={selectedCurrency} />
          {#if availableCurrencies.has("BAT")}
            <div class="text-small-regular rounded-m bg-primary-20 py-l px-xl text-text-primary content-center">
              <!-- TODO: Determine link for Learn more -->
              Save up to {getFormattedDiscount(discounts.bat)} by paying with BAT.
              <a href="#" class="text-text-primary" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
          {/if}
        </div>
      {/if}
      {#each priceOptions as option}
        <div class="-first:border-t border-divider-subtle/40 px-xl py-l text-text-tertiary">
          <RadioButton isDisabled={!product.browserSupport.isSupported} name="payment-term" size="normal" bind:currentValue={selectedPeriod} value={option.period}>
            <div class="grid w-full grid-cols-2 items-center">
              <div class="flex items-start gap-x-m max-sm:flex-col sm:items-center">
                <div>{periodOptions.get(option.period).description}</div>
                {#if option.period === "year"}
                  <Label color="green">BEST VALUE</Label>
                {/if}
              </div>
              <div class="text-heading-h2 flex items-end gap-x-m text-text-primary max-sm:flex-col sm:items-center">
                {getFormattedPrice(option.price)}
                <span class="text-default-regular text-text-tertiary">{periodOptions.get(option.period).suffix}</span>
              </div>
            </div>
          </RadioButton>
        </div>
      {/each}
    </div>
  </section>
  {#if product.browserSupport.isSupported}
    <section class="box flex flex-col gap-2xl rounded-none shadow-none max-sm:p-2xl">
      <slot />
    </section>
  {:else}
    <section class="md:box rounded-none shadow-none md:p-xl">
      <div class="overflow-hidden border-divider-subtle md:rounded-m md:border">
        <UnsupportedSystem>
          {product.browserSupport.message}
        </UnsupportedSystem>
      </div>
    </section>
  {/if}
</main>
